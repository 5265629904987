
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import TariffRadioButton from './TariffRadioButton.vue'
import { useBillingStore } from '../../../../store/stores/billing'
import { useProject } from '../../../../hooks/useProject'
export default defineComponent({
    components: { TariffRadioButton },
    props: {},
    setup(props, { emit }) {
        const billingStore = useBillingStore()
        const { project } = useProject()
        const currentTariff = computed(() => billingStore.getCurrentTariff(project.value.id))
        const selectTariff = (tariffId: string) => {
            billingStore.selectedTariffId = tariffId
        }
        onMounted(() => {
            if (currentTariff.value) {
                billingStore.selectedTariffId = currentTariff.value.integrationId
            }
        })
        watch(currentTariff, ()=>{
            selectTariff(currentTariff.value.integrationId)
        })
        return { billingStore, selectTariff }
    },
})
