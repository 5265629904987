export const tariffsFeatures = [
    {
        name: 'Работа с клиентской базой',
        children: [
            {
                name: 'Создание активаций из шаблонов',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Создание активаций из шаблонов - Создание активаций/сегментаций/выборок по готовым шаблонам',
            },
            {
                name: 'Чат-бот',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Чат бот - Это бот, который может уведомлять о новой записи, напоминать о записи, подтверждать или отменять запись, собирать отзывы, отправлять уникальный контент, когда клиент в кресле объединять несколько сообщений в одно, если записей несколько, а так же сообщать о списании и начислении бонусов, отправлять коды-подтверждения и многое другое. Только для YCLIENTS',
            },
            {
                name: 'Умная рассылка по активациям',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Умная рассылка по активациям - Подключение WhatsApp бота к рассылкам по умным активациям/сегментациям/выборкам с возможностью отправлять сообщения, как точечно так и массово.',
            },
            {
                name: 'Встроенный чат WhatsApp в YCLIENTS',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Встроенный чат WhatsApp в YCLIENTS - Возможность переписываться с клиентами в Whatsapp прямо внутри YCLIENTS',
            },
            {
                name: 'Подключение телефонии',
                inTariffs: ['tariff2', 'tariff3'],
                description:
                    'Подключение телефонии - Возможность подключения телефонии к проекту (список телефоний доступен в статье)',
            },
            {
                name: 'Возможность подключения нескольких номеров WhatsApp к проекту',
                inTariffs: ['tariff2', 'tariff3'],
                description:
                    'Возможность подключения нескольких номеров WhatsApp к проекту - При использовании YCLIENTS возможно подключить до 2 наших ботов с разными номерами телефонов для разных целей рассылок и уведомлений',
            },
            {
                name: 'Кастомные активации',
                inTariffs: ['tariff2'],
                description:
                    'Кастомные активации - Персональные активации/сегментации/выборки по вашим персональным запросам',
            },
            {
                name: 'Библиотека активаций',
                inTariffs: ['tariff2'],
                description:
                    'Библиотека активаций - Библиотека готовых активаций/сегментаций/выборок. Эта библиотека основана на нашей аналитике, это те активации, которые возвращают больше всего клиентов',
            },
            {
                name: 'Библиотека скриптов',
                inTariffs: ['tariff2'],
                description:
                    'Библиотека скриптов - Библиотека готовых скриптов. Эта библиотека основана на нашей аналитике, это те скрипты, которые возвращают больше всего клиентов',
            },
            {
                name: 'Теги активаций',
                inTariffs: ['tariff2'],
                description:
                    'Теги активаций - Возможность создавать теги/фильтры по активациям/сегментациям/выборкам и фильтровать их в удобном виде',
            },
            {
                name: 'Теги клиентов',
                inTariffs: ['tariff2'],
                description:
                    'Теги клиентов - Возможность создавать теги/фильтры коммуникаций с клиентами, чтобы в будущем понимать реальную причину оттока клиентов или же понять, какой % клиентов является вашей постоянной базой',
            },
            {
                name: 'Встроенный чат WhatsApp в Data Heroes (в разработке)',
                inTariffs: ['tariff2'],
                description:
                    'Встроенный чат в Data Heroes - Возможность просматривать и вести переписку в WhatsApp, прослушивать и видеть все входящие и исходящие звонки через нашу систему, а так же контролировать общение администраторов с клиентами',
            },
        ],
    },
    {
        name: 'Обучение',
        children: [
            {
                name: 'Ежемесячный вебинар',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Ежемесячный вебинар - Ежемесячный вебинар с представителями Data Heroes',
            },
            {
                name: 'Обучающий курс',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Обучающий курс - Обучающий видеокурс по работе в системе',
            },
            {
                name: 'База знаний',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'База знаний - Инструкции по работе в системе и ответы на частые вопросы',
            },
        ],
    },
    {
        name: 'Базовое',
        children: [
            {
                name: 'Интеграция с CRM',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Интеграция с CRM - Возможность интеграции с CRM системой из списка',
            },
            {
                name: 'Безлимит пользователей',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Безлимит пользователей - Неограниченное количество пользователей проекта',
            },
            {
                name: 'Модуль задачи',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Модуль Задачи - Формирование списка рекомендаций напоминаний клиентам записаться на визит, исходя из анализа их посещений, т.е. с использованием Искусственного интеллекта',
            },
            {
                name: 'Техподдержка по почте',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Техподдержка по почте - Техническая поддержка по почте info@dataheroes.pro',
            },
            {
                name: 'Техподдержка в чате',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Техподдержка в чате - 79651057194 (WhatsApp) или Telegram',
            },
            {
                name: 'Интеграция с АМО',
                inTariffs: ['tariff2'],
                description: 'Интеграция с АМО - Возможность интеграции с AMO',
            },
            {
                name: 'Управление правами',
                inTariffs: ['tariff2', 'tariff3'],
                description:
                    'Управление правами - Гибкая настройка прав доступа пользователей проекта, можно настроить разные права у директора, управляющего и администратора',
            },
            {
                name: 'Выделенный сотрудник',
                inTariffs: [],
                description:
                    'Выделенный сотрудник - это опция для крупных клиентов, когда внутри компании есть отдельный сотрудник, который решает все вопросы с оплатами, проблемы с техподдержкой и т.д.',
            },
        ],
    },
    {
        name: 'Аналитика и управление',
        children: [
            {
                name: 'Доктор',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Доктор - Отчет Доктор Data Heroes',
            },
            {
                name: 'Еженедельный отчет',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description: 'Еженедельный отчет - Автоматический еженедельный отчет',
            },
            {
                name: 'Расширенная аналитика коммуникаций',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Расширенная аналитика коммуникаций - Аналитика итогов коммуникаций с клиентами, кто в итоге записался и пришел, кто отменился, а кто и вовсе вас проигнорировал',
            },
            {
                name: 'Аналитика',
                inTariffs: ['tariff2', 'tariff3'],
                description:
                    'Аналитика - Аналитические отчеты по вашему проекту, включая ОПЗ, удержание клиентов, работу сотрудников, средний чек и прочее',
            },
            {
                name: 'KPI',
                inTariffs: ['tariff2', 'tariff3'],
                description:
                    'KPI - Тонкая настройка kpi администраторов, включая разные ставки в зависимости от холодности клиента, а также суммы, на которую он пришел',
            },
            {
                name: 'Ежедневный отчет',
                inTariffs: ['tariff1', 'tariff2', 'tariff3'],
                description:
                    'Ежедневный отчет - Автоматический ежедневный отчет о состоянии компании за день',
            },
            {
                name: 'Сетевой отчет',
                inTariffs: [],
                description:
                    'Сетевой отчет (разработка) - сводный отчет для управляющих компаний и владельцев большого количества салонов по вашей форме',
            },
        ],
    },
    {
        name: 'Экспертные модули',
        children: [
            {
                name: 'Импорт из Excel',
                inTariffs: ['tariff2'],
                description: 'Импорт из Excel - Выгрузка данных из проекта в Excel',
            },
            {
                name: 'Экспорт в Excel',
                inTariffs: ['tariff2'],
                description: 'Экспорт в Excel - Загрузка данных в проект из Excel',
            },
            {
                name: 'Отлавливание пропущенных звонков',
                inTariffs: ['tariff2', 'tariff3'],
                description:
                    'Отлавливание пропущенных звонков (разаработка) - Возможность увидеть, сколько было пропущено администраторами входящих звонков',
            },
            {
                name: 'История действий',
                inTariffs: [],
                description:
                    'История действий (разработка) — отчет, в котором видно, кто из сотрудников какие действия совершал в проекте в DH',
            },
            {
                name: 'Партнерские продукты',
                inTariffs: [],
                description:
                    'Партнерские продукты (разработка) — продукты, сделанные DH совместно с партнерами — экспертами на рынке красоты и здоровья. Эти продукты не входят в пакет, но их можно приобрести дополнительно в маркетплейсе DH',
            },
        ],
    },
]