
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import SmallHint from '@/components/SmallHint.vue'
import { useBillingStore } from '../../../../store/stores/billing'
import { tariffsFeatures } from '../../billing.consts'
export default defineComponent({
    props: { checked: { type: Boolean, default: false }, selectedTariff: { type: String } },
    components: { SmallHint },
    setup(props, { emit }) {
        const billingStore = useBillingStore()
        const tableData = [
            {
                integrationId: 1,
                integrationName: 'Название модуля 1',
                includes: {
                    tariff1: true,
                    tariff2: true,
                    tariff3: true,
                },
            },
            {
                integrationId: 2,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: false,
                    tariff2: true,
                    tariff3: true,
                },
            },
            {
                integrationId: 3,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: true,
                    tariff2: false,
                    tariff3: true,
                },
            },
            {
                integrationId: 4,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: true,
                    tariff2: true,
                    tariff3: false,
                },
            },
            {
                integrationId: 5,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: false,
                    tariff2: true,
                    tariff3: true,
                },
            },
            {
                integrationId: 6,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: false,
                    tariff2: true,
                    tariff3: true,
                },
            },
            {
                integrationId: 7,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: true,
                    tariff2: false,
                    tariff3: true,
                },
            },
            {
                integrationId: 8,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: true,
                    tariff2: true,
                    tariff3: true,
                },
            },
            {
                integrationId: 9,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: true,
                    tariff2: true,
                    tariff3: true,
                },
            },
            {
                integrationId: 10,
                integrationName: 'Название модуля',
                includes: {
                    tariff1: true,
                    tariff2: false,
                    tariff3: true,
                },
            },
        ]
        const getIntegrationIcon = (item: any, tariffId: string) => {
            return item.inTariffs.includes(tariffId) ? 'mdi-check-circle' : 'mdi-minus-circle'
        }
        const getIntegrationIconColor = (item: any, tariffId: string) => {
            return item.inTariffs.includes(tariffId) ? 'main' : undefined
        }
        return { tableData, getIntegrationIcon, getIntegrationIconColor, billingStore, tariffsFeatures }
    },
})
