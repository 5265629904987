
import { defineComponent, ref } from 'vue'
import { useBillingStore } from '../../../../store/stores/billing'
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import api from '@api/api'
import { useProject } from '@/hooks/useProject'
import { useModalStore } from '@/store/stores/modal'
import { TASKLIST_ACTION_TYPES } from '@/vars/GCB2'


export default defineComponent({
    components: { BaseInputOutlined },
    setup(props, { emit }) {
        const { project } = useProject()
        const modalStore = useModalStore()
        const billingStore = useBillingStore()
        const code = ref('')
        const activated = ref(false)
        const activate = async () => {
            const { data, error } = await api.marketplace.activatePromocode({
                projectId: project.value.id,
                promocode: code.value
            } as any)
            let resultText = ''
            switch (data?.message) {
                case 'Promocode not found':
                    resultText = 'Промокод не найден'
                    break
                case 'Promocode has expired':
                    resultText = 'Промокод истек'
                    break
                case 'Promocode usage limit reached':
                    resultText = 'Достигнут лимит использования промокода'
                    break
                case 'Promocode already used for this project':
                    resultText = 'Промокод уже использован для этого проекта'
                    break
                case 'Failed to activate promocode':
                    resultText = 'Не удалось активировать промокод'
                    break
                case 'Promocode successfully activated':
                    resultText = 'Промокод успешно активирован'
                    activated.value = true
                    billingStore.fetchTariffs(project.value.id)
                    break
                default:
                    resultText = data?.message
            }
            modalStore.open({title: 'Активация промокода', text: resultText })
        }
        return { code, activate, activated }
    },
})
