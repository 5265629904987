
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useBillingStore } from '../../../../store/stores/billing'
import { useProject } from '../../../../hooks/useProject'

export default defineComponent({
    props: {
        tariffId: { type: String, required: true },
        checkedByDefault: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const { project } = useProject()
        const billingStore = useBillingStore()
        const checked = computed(
            () => billingStore.selectedTariffId === props.tariffId || props.checkedByDefault
        )
        const currentTariff = computed(() => billingStore.getCurrentTariff(project.value.id))
        const isCurrentTariff = computed(() => currentTariff.value?.integrationId === props.tariffId)
        const text = computed(() => (isCurrentTariff.value ? 'ТЕКУЩИЙ ТАРИФ' : 'ВЫБРАТЬ ТАРИФ'))
        const icon = computed(() => (checked.value ? '$radioButtonChecked' : '$radioButtonUnchecked'))
        const onclick = () => {
            emit('input', props.tariffId)
        }
        return { icon, checked, onclick, isCurrentTariff, text }
    },
})
